import {pgettext} from "./lit-directives.js";

export function pad2(n) {
    if (n < 10) {
        return '0' + n;
    }
    return n;
}

function floatToCoord(f, positiveSuffix, negativeSuffix) {
    let suffix = positiveSuffix;
    if (f < 0) {
        f = -f;
        suffix = negativeSuffix;
    }
    const deg = Math.floor(f);
    let min = '' + (Math.round((60 * (f - deg)) * 1000.0) / 1000.0);
    if (min * 1 < 10) {
        min = '0' + min;
    }
    if (min.length === 2) {
        min += '.000';
    }
    while (min.length < 6) {
        min += '0';
    }
    return [deg, 'º ', min, suffix];
}

// These two functions return a result suitable for lit-html rendering.
// Implementations returning strings are found in routing.js
export function formatLatitude(lat) {
    return lat !== '' ? floatToCoord(lat, [' ', pgettext('North, 1 letter', 'N')], [' ', pgettext('South, 1 letter', 'S')]) : '';
}

export function formatLongitude(lon) {
    return lon !== '' ? floatToCoord(lon, [' ', pgettext('East, 1 letter', 'E')], [' ', pgettext('West, 1 letter', 'W')]) : '';
}

export const ROUTING_INVALID_VALUE = 9999; // TODO: remove when the router no longer outputs magic numbers (when current test-router goes live)

export function formatRounded(n, d) {
    if (!n) return n;   // covers '', null, undefined and 0
    n = n.toFixed(d);
    let str = n + '';
    if (d > 0) {
        let dotPos = n.indexOf('.');
        if (dotPos === -1) {
            dotPos = str.length;
            str += '.';
        }
        while (str.length - 1 - dotPos < d) {
            str += '0';
        }
    }
    return str;
}

export function formatTimestamp(timestamp, showYear) {
    if (timestamp === null || isNaN(timestamp)) {
        if (typeof timestamp === 'string') {
            return timestamp;
        } else {
            return '';
        }
    }
    if (!timestamp) {
        return '';
    }

    const date = new Date();
    date.setTime(timestamp * 1000);

    let result = '';
    result += date.getUTCDate() + ' ' + window.GET_MONTH_NAME_3(date.getUTCMonth());
    if (showYear) {
        result += " " + date.getUTCFullYear() + ",";
    }
    result += " " + pad2(date.getUTCHours()) + ":" + pad2(date.getUTCMinutes());
    return result;
}

export function formatTwa(twa) {
    if (typeof twa == 'undefined' || twa === null || twa === '') {
        return twa;
    }
    twa = twa * 1;
    if (twa < 0) {
        twa = "<span style='color:red'>P</span>" + formatRounded(-twa, 0);
    } else if (twa > 0) {
        twa = "<span style='color:green'>S</span>" + formatRounded(twa, 0);
    } else {
        twa = '0';
    }
    return twa;
}

export function formatTableTwa(twa) {
    if (typeof twa == 'undefined' || twa === null || twa === '') {
        return '';
    }
    if (Math.abs(twa) < 35) {
        return 'VMG';
    }
    return formatTwa(twa);
}

function gettext_for_days(days) {
    return interpolate(ngettext('%s day', '%s days', days), [days]);
}

export function formatTimeDifference(dt) {
    const dMinutes = Math.floor(dt / 60);
    const days = Math.floor(dMinutes / 60 / 24);
    const hours = Math.floor(dMinutes / 60) % 24;
    const minutes = dMinutes % 60;
    const seconds = Math.floor(dt % 60);
    let timeTaken = [];
    if (days > 0) {
        timeTaken.push(gettext_for_days(days), ' ');
    }
    if (days > 0 || hours > 0) {
        timeTaken.push(hours + 'h ');
    }
    if (minutes > 0 || seconds > 0) {
        timeTaken.push(minutes + 'm ');
    }
    if (seconds > 0) {
        timeTaken.push(seconds + 's');
    }
    return timeTaken;
}

export function format_dp(n, dp) {
    if (n === null || isNaN(n)) return n;
    return (n * 1).toFixed(dp) + '';
}
export function format0dp(n) {
    return format_dp(n, 0);
}
export function format1dp(n) {
    return format_dp(n, 1);
}
export function format2dp(n) {
    return format_dp(n, 2);
}
export function format3dp(n) {
    return format_dp(n, 3);
}

export function isset(x, ignoreValue) {
    if (typeof ignoreValue == 'undefined') {
        ignoreValue = null;
    }
    return typeof x != 'undefined' && x !== null && x !== ignoreValue;
}

export function shouldShowFuelConsumption() {
    if ($j("#setup").length) {
        return $j('#setup').hasClass('Power') || $j('#setup').hasClass('PowerRouting');
    } else {
        return true;
    }
}
